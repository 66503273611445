function brandPopupCursor() {
  const customCursor = document.querySelector('.brand-popup__cursor');
  const brandPopupInner = document.querySelector('.brand-popup__inner');

  if (brandPopupInner !== null) {
    brandPopupInner.addEventListener('mousemove', moveText)
    brandPopupInner.addEventListener('mouseleave', hideText)
    
    function moveText(event) {
        customCursor.style.opacity = '1';
        customCursor.style.left = `${event.clientX}px`;
        customCursor.style.top = `${event.clientY}px`;
    }

    function hideText() {
        customCursor.style.opacity = '0';
    }

    // Плавное скрытие блока с текстом при выходе за пределы .brand-popup__inner
    brandPopupInner.addEventListener('mouseleave', () => {
        customCursor.style.opacity = '0';
    });
  }
}
brandPopupCursor();
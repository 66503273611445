import { disablePageScroll, enablePageScroll } from "scroll-lock";

function toggleMobileMenu() {
  let button = $('.header__menu');
  let mobileMenu = $('.header-mobile');
  let close = $('.header-mobile__close');
  let popup = $('.search-popup');
  let item = $('.header-mobile__blocks');
  let listMobile = $('.header-mobile__list');
  let listTablet = $('.header-tablet');
  let inputMob = $('.header-mobile__input');
  let category = $('.header-mobile__block');

  button.on('click', function() {
    mobileMenu.addClass('active');
    disablePageScroll();
  });
  close.on('click', function() {
    inputMob.removeClass('focus');
    inputMob.val('');
    popup.removeClass('active');
    mobileMenu.removeClass('active');
    enablePageScroll();
    if (item.hasClass('active')) {
      item.removeClass('active').show();
      item.children(listMobile).removeClass('active');
      item.children(listTablet).removeClass('active');
      item.children(category).removeClass('active');
    }
  });
};
toggleMobileMenu();


function listToggleMenu() {
  let item = $('.header-mobile__block');

  item.on('click', function() {
    let listMobile = $(this).next('.header-mobile__list');
    let listTablet = $(this).next().next('.header-tablet');

    if ($(window).width() < 768) {
      $(this).toggleClass('active');
      listMobile.toggleClass('active');
      item.not($(this)).toggle();
      disablePageScroll();

    } else {
      $(this).toggleClass('active');
      listTablet.toggleClass('active');
      item.not($(this)).toggle();
      enablePageScroll();

    }
  });
}
listToggleMenu();

function catalogToggleMenu() {
  let button = $('.header__catalog');

  button.on('mouseenter', function(){
    let menuID = $(this).data('menu');
    $('#' + menuID).addClass('active');
  });

  button.on('mouseleave', function(){
    let menuID = $(this).data('menu');
    $('#' + menuID).removeClass('active');
  });
}

catalogToggleMenu();

function searchToggle() {
  let popup = $('.search-popup');
  let input = $('.header__input');
  let form = $('.header__form');
  let submit = $('.header__form button');
  
  let inputMob = $('.header-mobile__input');
  let buttonMob = $('.header-mobile__form button');
  let search = $('.header__button');
  let popupMob = $('.header-mobile');

  

  let windowWidth = $(window).width();

  if (windowWidth >= 1280) {
    submit.on('click', function() {
      input.toggleClass('active');
      input.removeAttr('disabled');
      if (input.hasClass('active')) {
        input.attr('placeholder', 'Поиск...');
        input.removeAttr('disabled', 'disabled');
      } else {
        input.removeAttr('placeholder');
        input.attr('disabled', 'disabled');
        popup.removeClass('active');
        input.removeClass('focus');
        input.val('');
      }
    });
  
    input.on('input', function() {
      if ($(this).val() !== '') {
        submit.attr('type', 'submit');
        input.addClass('focus');
        popup.addClass('active');
      } else {
        input.removeClass('focus');
        popup.removeClass('active');
        submit.attr('type', 'button');
      }
    });
  
    $(document).mouseup( function(e){
      if ( !form.is(e.target) && form.has(e.target).length === 0 ) { 
        input.removeClass('active');
        input.removeClass('focus');
        input.removeAttr('placeholder');
        input.attr('disabled', 'disabled');
        input.val('');
        popup.removeClass('active');
      }
    });

   
  } 
  else {
    search.on('click', function() {
      popupMob.addClass('active')
    });

    inputMob.on('input', function() {
      if (inputMob.val() !== '') {
        inputMob.addClass('focus');
        popup.addClass('active');
        buttonMob.attr('type', 'submit');
      } else {
        inputMob.removeClass('focus');
        popup.removeClass('active');
        buttonMob.attr('type', 'button');
      }
    });
  }
  
}

searchToggle();
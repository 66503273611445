import "./assets/styles/app.scss";
import 'swiper/css';
import 'swiper/css/bundle';

if (module.hot) {
  module.hot.accept();
}

$(function () {
  require("js/app");
});
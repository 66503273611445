import Inputmask from "inputmask";
import sendMail from "./sendMail.js"

export default function sendForm() {

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validateName = (name) => {
    if (name.length >= 2 && name.length < 50) {
      return name.match(
        /^[а-яА-Я]/
      );
    }
  }

  const validatePhone = (phone) => {
    return phone.match(
      /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
    );
  }

  const validatedName = function() {
    const name = $(this).val();

    if (validateName(name)) {
      $(this).removeClass('error').addClass('filled');
    } else {
      $(this).addClass('error').removeClass('filled');
      $('.footer-callback__error').show();
    }
    if (name == '') {
      $(this).removeClass('error').removeClass('filled');
    }
    return false;
  }

  const validatedEmail = function() {
    const email = $(this).val();

    if (validateEmail(email)) {
      $(this).removeClass('error');
      $(this).addClass('filled');
    } else {
      $(this).addClass('error');
      $(this).removeClass('filled');
    }
    if (email == '') {
      $(this).removeClass('error').removeClass('filled');
    }
    return false;
  }

  const validatedPhone = function() {
    const phone = $(this).val();

    if (validatePhone(phone) && phone.length > 0) {
      $(this).removeClass('error');
      $(this).addClass('filled');
    } else {
      $(this).addClass('error');
      $(this).removeClass('filled');
      $('.footer-callback__error').show();
    }
    if (phone == '') {
      $(this).removeClass('error').removeClass('filled');
    }
    return false;
  }

  $('input[name="name"]').on('input', function() {
    validatedName.call(this);
  });
  $('input[name="email"]').on('input', function() {
    validatedEmail.call(this);
  });
  $('input[name="phone"]').on('input', function() {
    validatedPhone.call(this);
  });

  $('.footer-callback__form input').on('input', function() {
    if (!$('.footer-callback__form input').hasClass('error')) {
      $('.footer-callback__error').hide();
    }
  });

  function sendFooterForm() {
    let form = $('.footer-callback__form');
    if (form) {
      form.on('submit', function(e) {
        e.preventDefault();
        if (
          !$('input[name="name"]').hasClass('error') &&
          !$('input[name="phone"]').hasClass('error')) {
          sendMail(form).then(function() {
            form.get(0).reset();
            $('input').removeClass('error').removeClass('filled');
            $('.footer-callback__error').hide();
          });
        }
      });
    }
  }
  sendFooterForm();

  function sendFooterSubscription() {
    let form = $('.footer-mailing__form');
    if (form) {
      form.on('submit', function(e) {
        e.preventDefault();
        if (!$('input[name="email"]').hasClass('error')) {
          sendMail(form).then(function() {
            form.get(0).reset();
            $('input').removeClass('error').removeClass('filled');
          });
        }
      });
    }
  }
  sendFooterSubscription();

  function inputMask() {
    let input =  $('input[type="tel"]');
    Array.from(input).forEach(function(element) {
      let mask = new Inputmask('+7 (999) 999-99-99');
      mask.mask(element);
    });
  }
  inputMask();

}
setTimeout(function() {
  sendForm();
}, 100);

import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';


function brandPopupSlider() {

  if ($('.brand-popup').length > 0) {
    const breakpoint = window.matchMedia( '(min-width:768px)' );
    let mySwiper;

    const breakpointChecker = function() {
      // if larger viewport and multi-row layout needed
      if ( breakpoint.matches === true ) {
        // clean up old instances and inline styles when available
        if ( mySwiper !== undefined ) mySwiper.destroy( true, true );
        // or/and do nothing
        return;
      // else if a small viewport and single column layout needed
      } else if ( breakpoint.matches === false ) {
        // fire small viewport version of swiper
        return enableSwiper();
      }
    };

    const enableSwiper = function() {
      mySwiper = new Swiper('.brand-popup .swiper', {
        modules: [ Navigation ],
        speed: 800,
        slidesPerView: 1,
        spaceBetween: 20,
        loop: true,
        navigation: {
          nextEl: ' .brand-popup__navigation .swiper-button-next',
        },
        // breakpoints: {
        //   768: {
        //     enabled: false,
        //     cleanStyles: true
        //   },
        // }
      });
    }
    

    // keep an eye on viewport size changes
    breakpoint.addListener(breakpointChecker);
    // kickstart
    breakpointChecker()
  }
}
brandPopupSlider();

function cubeShow() {
  if ($(window).height() > 640) {
    $('.swiper-brand-cube').show();
    $('.brand-hero__content').removeClass('active');
  } else {
    $('.swiper-brand-cube').hide();
    $('.brand-hero__content').addClass('active');
  }
}

cubeShow();
$(window).on('resize', cubeShow);

cubeShow();
import Swiper from 'swiper';

function postOthersSlider() {
  new Swiper('.swiper-others', {
    speed: 800,
    slidesPerView: 1.37,
    spaceBetween: 10,
    breakpoints: {
      768: {
        slidesPerView: 3,
        allowTouchMove: false,
        spaceBetween: 10
      },
    }
  });
};
postOthersSlider();

function brandProduct() {
  let button = $('.brand-product__button');
  let popup =  $('.brand-product-popup');

  button.on('click', function() {
    $(this).parent().next(popup).toggleClass('active');
    let text = $(this).find('span');
    if (text.text() === 'Состав'){
        text.text('Закрыть');
    } else {
      text.text('Состав');
    }
  });

}
brandProduct();
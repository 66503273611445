import { disablePageScroll, enablePageScroll } from "scroll-lock";

function brandPopup() {
  let button = $('.brand-hero__link');
  let popup =  $('.brand-popup');
  let close =  $('.brand-popup__button');
  button.on('click', function() {
    popup.addClass('active');
    disablePageScroll();
  });
  close.on('click', function() {
    popup.removeClass('active');
    enablePageScroll();
  });
}
brandPopup();
function newsFilter() {
  let button = $('.news-filter-dropdown__button');
  let container = $('.news-filter-dropdown__items');
  let items = $('.news-filter-dropdown__items span');
  button.on('click', function() {
    $(this).toggleClass('active');
    container.toggleClass('active');
  });
  Array.from(items).forEach(function(el) {
    $(el).on('click', function() {
      $('.news-filter-dropdown__value').text($(this).text());
      $('.news-filter-dropdown__input').val($(this).text());
      button.removeClass('active');
      container.removeClass('active');
    });
  });
  $(document).mouseup( function(e){
		if (!container.is(e.target) && container.has(e.target).length === 0 && !button.is(e.target) && button.has(e.target).length === 0 ) {
      button.removeClass('active');
      container.removeClass('active');
		}
	});
}
newsFilter();
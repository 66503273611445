import Swiper from 'swiper';
import { EffectFade, Mousewheel, EffectCube, Thumbs, Pagination } from 'swiper/modules';

function brandSlider() {
  var swiper = new Swiper('.swiper-brand-cube', {
    modules: [EffectCube, Thumbs],
    allowTouchMove: false,
    effect: "cube",
    speed: 1200,
    loop: true,
    thumbs: {
      swiper: swiperMain,
    },
  });
  var swiperMain = new Swiper('.swiper-brand-bg', {
    modules: [EffectFade, Mousewheel, Thumbs, Pagination],
    loop: true,
    effect: 'fade',
    speed: 1200,
    mousewheel: true,
    fadeEffect: {
      crossFade: true
    },
    thumbs: {
      swiper: swiper,
    },
    pagination: {
      el: '.brand-hero-pagination .swiper-pagination',
      type: 'fraction'
    },
    breakpoints: {
      1280: {
        allowTouchMove: false,
      }
    }
  });
}
brandSlider();

function brandSliderCursor() {
  const customCursor = document.querySelector('.brand-hero__cursor');
  const brandPopupInner = document.querySelector('.brand-hero');

  if (brandPopupInner !== null && customCursor !== null) {
    brandPopupInner.addEventListener('mousemove', moveText)
    brandPopupInner.addEventListener('mouseleave', hideText)
    
    function moveText(event) {
        customCursor.style.opacity = '1';
        customCursor.style.left = `${event.clientX}px`;
        customCursor.style.top = `${event.clientY}px`;
    }

    function hideText() {
        customCursor.style.opacity = '0';
    }

    // Плавное скрытие блока с текстом при выходе за пределы .brand-popup__inner
    brandPopupInner.addEventListener('mouseleave', () => {
        customCursor.style.opacity = '0';
    });
  }
}
brandSliderCursor();
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

function brandProductSlider() {
  const myCustomSlider = document.querySelectorAll('.brand-product__item .swiper');
  const sections = document.querySelectorAll('.brand-product__item');

  for( let i = 0; i < myCustomSlider.length; i++ ) {
    
    myCustomSlider[i].classList.add('swiper-' + i);
    sections[i].classList.add('brand-product__item-' + i);
    new Swiper('.brand-product__item-' + i + ' .swiper-' + i, {
      modules: [ Pagination, Navigation ],
      speed: 1300,
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
      navigation: {
        nextEl: `.brand-product__item-${i} .swiper-button-next`,
        prevEl: `.brand-product__item-${i} .swiper-button-prev`,
      },
      pagination: {
        clickable: true,
        el: `.brand-product__item-${i} .swiper-pagination`,
      },
      breakpoints: {
        1280: {
          slidesPerView: 2.6,
        },
        1440: {
          slidesPerView: 2.77,
        },
        1920: {
          slidesPerView: 2.9,
        },
      }
    });
  }
};
brandProductSlider();

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

function heroSlider() {
  new Swiper('.hero .swiper', {
    modules: [ Navigation, Pagination ],
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 800,
    loop: true,
    navigation: {
      nextEl: '.hero .swiper-button-next',
      prevEl: '.hero .swiper-button-prev',
    },
    pagination: {
      clickable: true,
      el: '.hero .swiper-pagination',
    },
  });
};
heroSlider();

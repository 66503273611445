import Swiper from 'swiper';
import { Thumbs, EffectFade, Grid } from 'swiper/modules';

function brandsSlider() {
    // 1
  var swiperThumbs = new Swiper(".brands-card-thumbs1", {
    modules: [Grid],
    spaceBetween: 10,
    slidesPerView: 3,
    breakpoints: {
      1280: {
        direction: "horizontal",
        slidesPerView: 3,
        grid: {
          rows: 1,
        },
      },
      768: {
        direction: "vertical",
      },
    }
  });

  // 1
  new Swiper(".brands-card-slider1", {
    modules: [EffectFade, Thumbs],
    slidesPerView: 1,
    grabCursor: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    thumbs: {
      swiper: swiperThumbs,
    },
  });

  //2
  
  var swiperThumbs2 = new Swiper(".brands-card-thumbs2", {
    modules: [Grid],
    spaceBetween: 10,
    slidesPerView: 3,
    breakpoints: {
      1280: {
        direction: "horizontal",
        slidesPerView: 3,
        grid: {
          rows: 1,
        },
      },
      768: {
        direction: "vertical",
      },
    }
  });

  // 2
  new Swiper(".brands-card-slider2", {
    modules: [EffectFade, Thumbs],
    slidesPerView: 1,
    grabCursor: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    thumbs: {
      swiper: swiperThumbs2,
    },
  });

  ///3
  var swiperThumbs3 = new Swiper(".brands-card-thumbs3", {
    modules: [Grid],
    spaceBetween: 10,
    slidesPerView: 3,
    breakpoints: {
      1280: {
        direction: "horizontal",
        slidesPerView: 3,
        grid: {
          rows: 1,
        },
      },
      768: {
        direction: "vertical",
      },
    }
  });

  // 3
  new Swiper(".brands-card-slider3", {
    modules: [EffectFade, Thumbs],
    slidesPerView: 1,
    grabCursor: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    thumbs: {
      swiper: swiperThumbs3,
    },
  });

};
brandsSlider();

import { disablePageScroll, enablePageScroll } from "scroll-lock";

function toggleFilterPopup() {
  let button = $('.filter');
  let close = $('.popup-filter__close');
  let popup = $('.popup-filter');
  let submit = $('.popup-filter__button-submit');
  let cover = $('.cover');

  button.on('click', function() {
    popup.addClass('active');
    cover.addClass('active');
    disablePageScroll();
  });
  close.on('click', function() {
    popup.removeClass('active');
    cover.removeClass('active');
    enablePageScroll();
  });
  submit.on('click', function() {
    popup.removeClass('active');
    cover.removeClass('active');
    enablePageScroll();
  });

  $(document).mouseup( function(e){
		if ( !popup.is(e.target) && popup.has(e.target).length === 0 ) { 
      popup.removeClass('active');
      cover.removeClass('active');
      enablePageScroll();
		}
	});
};
toggleFilterPopup();
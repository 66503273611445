import Swiper from 'swiper';

function mainNewsSlider() {
  const swiper = new Swiper('.main-news .swiper', {
    speed: 800,
    slidesPerView: 1.26,
    spaceBetween: 10,
    breakpoints: {
      768: {
        slidesPerView: 3,
        allowTouchMove: false,
        spaceBetween: 10
      },
    }
  });
};
mainNewsSlider();

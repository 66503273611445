import Swiper from 'swiper';
import { Navigation, Thumbs, EffectFade } from 'swiper/modules';

function productCardSlider() {
  var swiperMain = new Swiper(".product-card-info .swiper-main", {
    modules: [ Thumbs, EffectFade ],
    spaceBetween: 10,
    loop: true,
    slidesPerView: 1,
    loopedSlides: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    thumbs: {
      swiper: swiper,
    },
  });
  
  var swiper = new Swiper(".product-card-info .swiper-products", {
    modules: [ Navigation, Thumbs ],
    spaceBetween: 10,
    slidesPerView: 4,
    loop: true,
    freeMode: true,
    navigation: {
      nextEl: ".product-card-info .swiper-button-next",
      prevEl: ".product-card-info .swiper-button-prev",
    },
    thumbs: {
      swiper: swiperMain,
    },
  });
};
productCardSlider();



import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

function mainNewSlider() {
  const myCustomSlider = document.querySelectorAll('.main-new .swiper');
  const sections = document.querySelectorAll('.main-new');

  for( let i = 0; i < myCustomSlider.length; i++ ) {
    
    myCustomSlider[i].classList.add('swiper-' + i);
    sections[i].classList.add('main-new-' + i);
    new Swiper('.main-new-' + i + ' .swiper-' + i, {
      modules: [ Navigation ],
      speed: 800,
      slidesPerView: 1.5,
      observeParents: true,
      observer: true,
      observeSlideChildren: true,
      observerUpdate: true,
      spaceBetween: 10,
      navigation: {
        nextEl: `.main-new-${i} .swiper-button-next`,
        prevEl: `.main-new-${i} .swiper-button-prev`,
      },
      breakpoints: {
        375: {
          slidesPerView: 1.77,
        },
        768: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 10
        },
      }
    });

  }
};
mainNewSlider();

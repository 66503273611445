import ScrollReveal from "scrollreveal";

function scrollInit() {
  const options_drawed = {
    duration: 600,
    afterReveal: (el) => {
      $(el).addClass('drawed');
    }
  };
  const options_showed = {
    duration: 1000,
    delay: 800,
    opacity: 0,
    mobile: false,
  };
  const options_showed_up = {
    duration: 1000,
    origin: "bottom",
    distance: "50px",
    delay: 300,
    opacity: 0,
    mobile: false,
  };
  const options_showed_up_delayed_400 = {
    duration: 1000,
    origin: "bottom",
    distance: "50px",
    delay: 400,
    opacity: 0,
    mobile: false,
  };
  const options_showed_up_delayed_500 = {
    duration: 1000,
    origin: "bottom",
    distance: "50px",
    delay: 500,
    opacity: 0,
    mobile: false,
  };
  const options_showed_up_delayed_600 = {
    duration: 1000,
    origin: "bottom",
    distance: "50px",
    delay: 600,
    opacity: 0,
    mobile: false,
  };
  const options_showed_up_delayed_700 = {
    duration: 1000,
    origin: "bottom",
    distance: "50px",
    delay: 700,
    opacity: 0,
    mobile: false,
  };
  const options_showed_delayed_400 = {
    duration: 1000,
    delay: 400,
    opacity: 0,
    mobile: false,
  };
  const options_showed_delayed_500 = {
    duration: 1000,
    delay: 500,
    opacity: 0,
    mobile: false,
  };
  const options_showed_delayed_600 = {
    duration: 1000,
    delay: 600,
    opacity: 0,
    mobile: false,
  };
  const options_showed_delayed_700 = {
    duration: 1000,
    delay: 700,
    opacity: 0,
    mobile: false,
  };
  const options_showed_delayed_800 = {
    duration: 1000,
    delay: 800,
    opacity: 0,
    mobile: false,
  };
  const options_showed_delayed_1000 = {
    duration: 1000,
    delay: 1000,
    opacity: 0,
    mobile: false,
  };
  const options_showed_down = {
    duration: 1000,
    origin: "top",
    distance: "50px",
    delay: 300,
    opacity: 0,
    mobile: false,
  };
  ScrollReveal().reveal(".reveal-drawed", options_drawed);
  ScrollReveal().reveal(".reveal-showed", options_showed);
  ScrollReveal().reveal(".reveal-showed-up", options_showed_up);

  ScrollReveal().reveal(".reveal-showed-up-delayed-400", options_showed_up_delayed_400);
  ScrollReveal().reveal(".reveal-showed-up-delayed-500", options_showed_up_delayed_500);
  ScrollReveal().reveal(".reveal-showed-up-delayed-600", options_showed_up_delayed_600);
  ScrollReveal().reveal(".reveal-showed-up-delayed-700", options_showed_up_delayed_700);

  ScrollReveal().reveal(".reveal-showed-delayed-400", options_showed_delayed_400);
  ScrollReveal().reveal(".reveal-showed-delayed-500", options_showed_delayed_500);
  ScrollReveal().reveal(".reveal-showed-delayed-600", options_showed_delayed_600);
  ScrollReveal().reveal(".reveal-showed-delayed-700", options_showed_delayed_700);
  ScrollReveal().reveal(".reveal-showed-delayed-800", options_showed_delayed_800);
  ScrollReveal().reveal(".reveal-showed-delayed-1000", options_showed_delayed_1000);

  ScrollReveal().reveal(".reveal-showed-down", options_showed_down);

}
scrollInit();